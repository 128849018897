.property-type {
  &__edit-record-btn {
    color: $g-brand-color-1;
  }

  &__cards-row {
    padding: 20px 0px;
  }
  &__card {
    display: flex;
    padding: 15px;
    color: $g-white;
    font-size: 12px;
    &--brand-5 {
      background-color: $g-brand-color-5;
    }
    &--brand-6 {
      background-color: $g-brand-color-6;
    }
    &--brand-7 {
      background-color: $g-brand-color-7;
    }
    &--brand-8 {
      background-color: $g-brand-color-8;
    }
  }
  &__card-icon {
    color: $g-dark;
    background-color: $g-white;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-right: 10px;
    &--brand-5 {
      color: $g-brand-color-5;
    }
    &--brand-6 {
      color: $g-brand-color-6;
    }
    &--brand-7 {
      color: $g-brand-color-7;
    }
    &--brand-8 {
      color: $g-brand-color-8;
    }
  }
}
