.property-notes {
  z-index: 2;
  bottom: 40px;
  position: fixed;
  right: 40px;
  background-color: $g-brand-color-1;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $g-white;
  transition: $g-trans-base;
  cursor: pointer;
  &__modal {
    position: relative;
  }
  &:hover {
    background-color: lighten($color: $g-brand-color-1, $amount: 2);
    font-size: 20px;
  }
}
