.equity-forecast {
  position: relative;
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
    color: $g-title-color;
  }
  &__details-table {
    margin-bottom: 20px;
  }
  &__timeline-field-wrapper {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__performace-timeline-select {
    width: 150px;
  }
}
