%card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600;
}

.dashboard {
  position: relative;
  &__loader {
    align-items: flex-start;
    padding-top: 200px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    color: $g-title-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__header-fields {
    display: flex;
  }
  &__header-field {
    display: flex;
    align-items: center;
  }
  &__header-field-label {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  &__header-share {
    margin-left: 10px;
    height: 38px;
  }
  &__card {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    width: 100%;
    transition: $g-trans-base;
    cursor: pointer;
    height: 100%;

    &:hover {
      transform: scale(1.05);
    }
    .ant-card-body {
      padding: 20px 15px;
    }
    &--no-padding {
      .ant-card-body {
        padding: 0px;
      }
    }
    &--full-height {
      height: calc(100% - 20px);
    }
    &--stats-card {
      height: 160px;
      .ant-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100px;
      }
      svg {
        height: 20px;
      }
    }
  }
  &__progress-card-header {
    @extend %card-header;
  }
  &__progress-card-values {
    margin-bottom: 5px;
    font-weight: 400;
  }
  &__np-header {
    @extend %card-header;
    font-size: 16px;
    padding: 20px 15px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  &__np-row {
    border-bottom: 1px solid #eef9f8;
    // height: 37px;
    font-size: 13px;
    padding: 18px 15px;
  }
  &__np-amount-wrapper {
    padding: 0px 5px;
    font-size: 12px;
    text-overflow: ellipsis;
  }
  &__stats-card-num-wrapper {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__stats-card-label-wrapper {
    font-weight: 600;
    font-size: 14px;
    color: #738782;
    text-align: center;
  }
  &__stats-card-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-progress-inner,
  .ant-progress-bg {
    border-radius: 0px;
  }
  &__portfolio-performance-section {
    margin-top: 20px;
  }
  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  &__header-title--medium {
    font-weight: 600;
  }
  &__client-emails-wrapper {
    display: flex;
    align-items: center;
  }
  &__client-email-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &__client-email-action {
    cursor: pointer;
    color: $g-brand-color-1;
    &--delete {
      color: $g-error;
    }
  }
  &__client-invite-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__client-invite-field {
    width: 85%;
    margin-bottom: 10px;
  }
  &__client-invite-btn {
    font-size: 20px;
    cursor: pointer;
    color: $g-brand-color-1;
    margin-right: 10px;
  }
}

.c-form-modal__dashboard-share {
  width: 450px !important;
}

.c-form-field__dashboard-internal-share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 20px;
}
