.settlement-days {
  padding-top: 20px;
  &__input-number {
    height: 40px;
    width: 250px;
    .ant-input-number-input {
      height: 40px;
    }
  }
}
