@import './cash_required_estimates/cash_required_estimates.style';
@import './annual_figures/annual_figures.style';
@import '../monthly_modeling/monthly_modeling.style';
@import '../equity_forecast/equity_forecast.style';
@import '../cashflow_projection/cashflow_projection.style';
@import './info/sell_form/sell_form.style';

.info {
  position: relative;
  &__row {
    margin-bottom: 30px;
    // padding: 20px 10px 20px 0px;
  }
  &__loader {
    top: 30%;
  }
  &__field {
    display: flex;

    @include bpm-sm {
      margin: 10px 0;
      align-items: center;
      justify-content: center;
    }
  }
  &__field-label {
    margin-right: 10px;
    font-weight: 500;
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    max-width: 400px;

    @include bpm-sm {
      margin: 0 auto;

      button {
        width: 200px !important;
        margin: 0 auto;
      }
    }

    @include bpm-xs {
      button {
        width: 100% !important;
      }
    }
  }

  &__sub-action {
    @include bpm-sm {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  &__savings-btn {
    height: 40px;
    margin-top: 25px;

    @include bpm-xs {
      width: 100%;
      margin-top: 0;
    }
  }

  &__divider {
    display: none;

    @include bpm-xs {
      display: block;
      margin-bottom: 0;
    }
  }

  &__breaker {
    display: none;

    @include bpm-xs {
      display: block;
      margin-bottom: 10px;
    }
  }

  .h-margin {
    @include bpm-xs {
      margin-top: 10px;
    }
  }

  .ant-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.9);
  }

  .ant-col-offset-2,
  .ant-col-offset-14 {
    @include bpm-xs {
      margin-left: 0;
    }
  }
}
