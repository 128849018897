.l-plan-dup {
  &__modal {
    border-radius: 20px;

    .ant-modal-body {
      background: $g-light-background;
      border-radius: 0 0 20px 20px;
      min-height: 650px;
      position: relative;
    }
    .ant-modal-close,
    .ant-modal-close-x {
      width: 71px;
      height: 71px;
      line-height: 71px;
      font-size: 22px;
    }
    .ant-modal-content {
      border-radius: 20px;
    }
    .ant-modal-header {
      border-radius: 20px 20px 0 0;
      padding: 24px 32px;

      .ant-modal-title {
        font-weight: 600;
        font-size: 24px;
        color: $g-black;
      }
    }
  }
}
