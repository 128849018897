.financial_projections {
  position: relative;
  &__header {
    margin-bottom: 25px;
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    color: $g-title-color;
  }
  &__details-table-wrapper {
    margin-bottom: 40px;
  }
  &__details-table-header {
    font-weight: 600;
    margin-bottom: 6px;
  }
}
