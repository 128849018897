%card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600;
}

.dashboard {
  position: relative;
  height: 100%;
  &__client-wrapper {
    padding: 40px;
  }
  &__loader {
    align-items: flex-start;
    padding-top: 200px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    color: $g-title-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__header-fields {
    display: flex;
  }
  &__header-field {
    display: flex;
    align-items: center;
  }
  &__header-field-label {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  &__card {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    width: 100%;
    transition: $g-trans-base;
    cursor: pointer;
    height: 100%;

    &:hover {
      transform: scale(1.05);
    }
    .ant-card-body {
      padding: 20px 15px;
    }
    &--no-padding {
      .ant-card-body {
        padding: 0px;
      }
    }
    &--full-height {
      height: calc(100% - 20px);
    }
    &--stats-card {
      height: 160px;
      .ant-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100px;
      }
      svg {
        height: 20px;
      }
    }
  }
  &__progress-card-header {
    @extend %card-header;
  }
  &__progress-card-values {
    margin-bottom: 5px;
    font-weight: 400;
  }
  &__np-header {
    @extend %card-header;
    font-size: 16px;
    padding: 20px 15px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  &__np-row {
    border-bottom: 1px solid #eef9f8;
    // height: 37px;
    font-size: 13px;
    padding: 18px 15px;
  }
  &__np-amount-wrapper {
    padding: 0px 5px;
    font-size: 12px;
    text-overflow: ellipsis;
  }
  &__stats-card-num-wrapper {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__stats-card-label-wrapper {
    font-weight: 600;
    font-size: 14px;
    color: #738782;
    text-align: center;
  }
  &__stats-card-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-progress-inner,
  .ant-progress-bg {
    border-radius: 0px;
  }
  &__portfolio-performance-section {
    margin-top: 20px;
  }
  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  &__header-title--medium {
    font-weight: 600;
  }
}

.l-otp {
  background-color: rgba(52, 64, 84, 0.7);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__form-wrapper {
    background-color: $g-white;
    border-radius: 12px;
    margin: 0px auto;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    @include bpm-sm {
      width: 400px;
    }
    @include bpm-xs {
      width: 300px;
      padding: 15px;
    }
  }

  &__form-title {
    font-size: 16px;
    font-weight: 700;
    font-family: $g-font-base;
    margin-bottom: 30px;
    color: $g-dark-color-2;
  }
  &__form-msg {
    font-weight: 600;
    font-size: 18px;
    font-family: $g-font-base;
    margin-bottom: 8px;
    color: $g-additional-color-7;
  }

  &__form-sub-msg {
    font-weight: 400;
    font-size: 14px;
    color: $g-additional-color-8;
    margin-bottom: 22px;
  }

  &__form-field {
    margin-bottom: 20px;
    width: 100%;

    input {
      font-size: $g-font-base;
    }
  }

  &__verify-button {
    height: 40px;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #e6f8f6;
    margin-bottom: 20px;
  }

  &__resend-code {
    position: relative;
    transition:
      transform 0.75s ease-out,
      -webkit-transform 0.75s ease-out;
    text-decoration: none;

    font-weight: 400;
    color: $g-additional-color-8;
    margin-bottom: 30px;
    a {
      text-decoration: none;
    }
    &--disabled {
      cursor: not-allowed;
      // opacity: 0.5;
      text-decoration: none;
    }

    &--enabled {
      cursor: pointer;
      color: $g-brand-color-1;
      &:hover {
        &::before {
          transform: scale(1);
          -webkit-transform-origin: left center;
          transform-origin: left center;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        right: 0;
        background: #f0e9e3;
        transition: -webkit-transform 0.25s ease-in;
        transition: transform 0.25s ease-in;
        transition:
          transform 0.25s ease-in,
          -webkit-transform 0.25s ease-in;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: right center;
        transform-origin: right center;
        height: 2px;
        background-color: $g-brand-color-1;
      }
    }
  }

  &__btn-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &__btn {
    width: 49%;
    border-radius: 8px;
    height: 44px;
    font-weight: 600;
    font-size: 16px;
  }
}

.otp-separator {
  font-weight: 500;
  font-size: 60px;
  color: $g-dark-color-8;
  @include bpm-sm {
    font-size: 2rem;
  }
}
