.l-deactivate-sell-modal {
  .ant-modal-body {
    padding: 32px;
    // background-color: $g-brand-color-1-lite;
    border-radius: 12px;
  }
  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-close {
    padding: 15px 10px;
  }

  &__body {
    position: relative;
  }

  &__header {
    padding: 10px 0px;
    font-weight: $g-font-weight-heading-base;
    font-family: $g-font-heading;
    color: $g-additional-color-7;
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 32px;
  }

  &__content {
    font-weight: 600;
    font-size: 16px;
    color: $g-additional-color-8;
    line-height: 24px;
    margin-bottom: 30px;
    font-family: $g-font-heading;
    strong {
      color: $g-additional-color-7;
    }
  }

  &__btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    height: 44px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    font-family: $g-font-heading;
    font-weight: 600;
  }
}
