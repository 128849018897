%link-decoration {
  cursor: pointer;
  color: $g-brand-color-1;
  &:hover {
    text-decoration: none;
    &::before {
      transform: scale(1);
      -webkit-transform-origin: left center;
      transform-origin: left center;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
    background: #f0e9e3;
    transition: -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in;
    transition:
      transform 0.25s ease-in,
      -webkit-transform 0.25s ease-in;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    height: 2px;
    background-color: $g-brand-color-1;
  }
}
