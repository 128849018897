.c-app-layout {
  height: 100% !important;
  min-height: 100%;
  overflow-y: scroll;
  &__header {
    background-color: $g-white;
    height: 65px;
    padding: 0 0 0 230px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include bpm-md {
      padding: 0 0 0 19px;
    }
    @include bpm-sm {
      height: unset;
      padding: 0;
    }
  }

  &__sold-indicator {
    width: 20px;
    background: $g-error;
    color: $g-white;
    border-radius: 50px;
    font-size: 14px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  &__header-logo {
    padding-left: 25px;
    width: 230px;
    display: flex;
    align-items: center;
  }
  &__header-left-side {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    max-height: 65px;
    @include bpm-md {
      padding-left: 10px;
    }
  }
  &__header-right-side {
    display: flex;
    justify-content: space-between;
    flex: 1 1 0;
    width: 100%;
    @include bpm-md {
      padding-right: 10px;
    }
    @include bpm-sm {
      padding-right: 0;
      flex-direction: column-reverse;
      align-items: flex-end;
      width: 100%;
    }
  }
  &__header-details-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    @include bpm-sm {
      padding: 0 0 10px;
    }
  }
  &__header-details {
    margin-right: 10px;
    @include bpm-sm {
      margin-right: 0;
      justify-content: space-between;
      padding: 0 10px;
    }
  }
  &__header-portfolio-type {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    background-color: lighten($color: $g-brand-color-2, $amount: 35%);
    color: $g-brand-color-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
    width: 100%;
    @include bpm-xl {
      max-width: 300px;
    }
    @include bpm-lg {
      max-width: 180px;
    }
    @include bpm-sm {
      flex: 1 1 0;
      max-width: unset;
      display: flex;
      justify-content: space-between;
    }
  }
  &__header-portfolio-type-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__header-user-dd {
    .ant-dropdown-menu-item {
      border-radius: 0px !important;
      font-weight: 500 !important;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #f2f4f7 !important;
    }
    li:has(.c-app-layout__profile-dd-info-wrapper) {
      padding-left: 10px !important;
    }
  }
  &__header-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 65px;
  }
  &__header-all-details-wrapper {
    margin-right: 10px;
    display: flex;
    @include bpm-sm {
      flex-direction: column;
      margin-right: 0;
      width: 100%;
      padding: 0 10px;
      line-height: 45px;
    }
  }
  &__header-nav {
    display: flex;
  }

  &__header-nav-item {
    font-weight: 600;
    font-size: 15px;
    color: #667085;
    padding: 0 5px;

    &:hover {
      color: #00c1b1;
      text-decoration: none;
    }

    &:first-child {
      margin-right: 30px;
    }

    &--active {
      color: #009387;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #009387;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__header-client {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid $g-dark-color-7;
    height: 45px;
    margin: 10px;
    padding: 5px 10px;
    max-width: 200px;
    width: 200px;
    font-weight: 600;
    color: $g-dark-color-3;
    justify-content: space-between;
    margin-right: 20px;
    @include bpm-sm {
      margin: 10px 0 9px;
      width: 100%;
      max-width: unset;
    }
  }
  &__header-client-name-wrapper {
    width: 140px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include bpm-sm {
      width: 100%;
    }
  }
  &__header-client-image-wrapper {
    margin-right: 10px;
    display: flex;
  }

  &__header-client-details {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include bpm-sm {
      width: 100%;
    }
  }

  &__header-nav-small {
    height: 65px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      margin-left: 5px;
      border-radius: 50%;
      border: 4px solid #d6f6f4;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__content {
    background-color: $g-white;
    margin-left: 25px;
    padding: 30px;
    overflow-y: scroll;
    @include bpm-lg {
      margin-left: 0;
    }

    @include bpm-sm {
      padding: 20px;
    }
  }

  &__sider {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // padding-bottom: 10px;
    }

    position: relative;
    @include bpm-lg {
      z-index: 1000;
      position: absolute !important;
      height: 100%;
      top: 0;
      left: 0;
    }

    .ant-layout-sider-zero-width-trigger {
      @include bpm-sm {
        top: 10px;
        inset-inline-end: -30px;
        z-index: 1;
        width: 30px;
        height: 30px;
      }
    }
  }

  &__sider-collapse-btn {
    height: 56px;
    border-radius: 8px;
    margin: 10px;
    color: $g-brand-color-1-dark;
    background: $g-brand-color-1-light1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    overflow: hidden;
    &--collapsed {
      padding: 0px 20px;
      justify-content: center;
      margin: 10px;
      span {
        height: 24px;
        width: 24px;
      }
    }
    span {
      display: inline-block;
    }
    &:hover {
      color: $g-brand-color-1-dark !important;
      background: $g-brand-color-1-light1 !important;
    }
  }

  &__sider-menu {
    overflow-y: scroll;
  }
}

.l-content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  &__title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 5px;
    color: $g-title-color;
  }
  &__subtitle {
    font-size: 16px;
    color: $g-sub-title-color;
  }
}
