.c-sold-label {
  margin-left: 10px;
  background: $g-error;
  border-radius: 16px;
  padding: 2px 8px;
  width: 49px;
  height: 22px;
  z-index: 5;
  // Font
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: $g-white;
  position: relative;
}
