.stamp_duty_lmi {
  &__edit-record-btn {
    color: $g-brand-color-1;
  }
  &__main-table {
    margin-bottom: 30px;
  }

  &__section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5;
  }
}
