.l-reset-pass {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__app-name {
    // TODO: use Montserat form for this
    font-weight: 500;
    font-size: 18px;
    color: $g-additional-color-6;
    line-height: 24px;
    margin-bottom: 24px;
  }
  &__reset-form-heading {
    font-weight: 600;
    font-size: 30px;
    color: $g-additional-color-7;
    margin-bottom: 24px;
  }
  &__form-btn {
    width: 100%;
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid $g-brand-color-1;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__additional-link {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
  }
  &__additional-link-msg {
    margin-right: 5px;
  }
  &__additional-link-trigger {
    position: relative;
    transition:
      transform 0.75s ease-out,
      -webkit-transform 0.75s ease-out;
    text-decoration: none;
    @extend %link-decoration;
    font-weight: 600;
  }
  &__content-wrapper {
    width: 400px;
    @include bpm-sm {
      padding: 15%;
      width: 100%;
    }
    @include bpm-xs {
      padding: 5%;
      width: 100%;
    }
  }
  &__form-wrapper {
    width: 400px;
    @include bpm-sm {
      width: 100%;
    }
    @include bpm-xs {
      width: 100%;
    }
  }
  &__form {
    width: 400px;
    @include bpm-sm {
      width: 100%;
    }
    @include bpm-xs {
      width: 100%;
    }
  }

  .c-form-field {
    .ant-input,
    .ant-input-password {
      border-radius: 8px;
    }
  }
}
