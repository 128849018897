.client-dash-login {
  display: flex;
  width: 100%;
  height: 100%;
  &__image-wrapper {
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 100%;
    flex: 1;
    height: 100%;
    // width: 50%;
    @include bpm-sm {
      display: none;
    }
  }
  &__form-wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 32px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    justify-content: center;
    .ant-input,
    .ant-input-password {
      border-radius: 8px;
    }
    .c-form-field__wrapper {
      margin-bottom: 10px;
    }
    @include bpm-sm {
      width: 60%;
    }
    @include bpm-xs {
      width: 100%;
    }
  }
  &__pgp-heading {
    font-weight: 500;
    font-size: 18px;
    color: $g-additional-color-6;
    line-height: 24px;
    margin-bottom: 24px;
  }
  &__form-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: $g-additional-color-7;
    margin-bottom: 12px;
  }
  &__form-sub-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $g-additional-color-8;
    margin-bottom: 24px;
  }
  &__form-btn {
    width: 100%;
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
  }
  &__form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__form-btn-forgot-pass {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    color: $g-brand-color-1;
  }
}
