.monthly-modeling {
  position: relative;
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    color: $g-title-color;
  }

  .h-text-align {
    text-align: right;

    @include bpm-sm {
      text-align: left;
    }
  }
}
