.h-client-modal {
  &__form-cancel-btn {
    margin-right: 20px;
    height: 40px;
  }

  &__form-submit-btn {
    width: 150px;
    height: 40px;
  }

  &__form-btn-wrapper {
    @include bpm-xs {
      justify-content: center;
    }
  }
}
