.l-focus {
  padding-top: 10px;
  &__section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__table-wrapper {
    margin-bottom: 50px;
  }
}
