.inputStyle {
  width: 4rem !important;
  height: 4rem !important;
  padding: 8px;
  margin: 0 5px;
  font-weight: 500;
  font-size: 3rem;
  border-radius: 8px;
  border: 1px solid $g-dark-color-8;
  color: $g-brand-color-1;
  // line-height: 60px;
  @include bpm-sm {
    width: 3rem !important;
    height: 3rem !important;
    font-size: 2rem;
  }

  @include bpm-xs {
    width: 2rem !important;
    height: 2rem !important;
    font-size: 1rem;
  }
}
