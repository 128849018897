.offset-account {
  position: relative;

  &__row {
    margin-bottom: 30px;
  }

  &__sub-action {
    @include bpm-sm {
      max-width: 400px;
      margin: 0 auto;
      margin: 12px 0px;
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    max-width: 400px;

    @include bpm-sm {
      margin: 0 auto;

      button {
        width: 200px !important;
        margin: 0 auto;
      }
    }

    @include bpm-xs {
      button {
        width: 100% !important;
      }
    }
  }
}

.editable-cell-value-wrap {
  min-width: 100px;
  min-height: 30px;
}
