.plan-view {
  height: 100%;
  width: 100%;
  padding: 25px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
