.l-sold-overlay {
  top: -30px;
  right: -30px;
  bottom: -30px;
  left: -30px;
  position: absolute;
  background: $g-dark-color-8;
  z-index: 4;
  opacity: 0.4;
}
