.purchase {
  position: relative;
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
    color: $g-title-color;
  }
  &__section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  &__section {
    margin-bottom: 20px;
  }
  &__section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__summary-tables {
    background-color: $g-additional-color-1;
    padding: 15px;
  }

  .ant-table {
    margin-bottom: 20px;
  }
}

.info {
  &__section-title {
    font-size: 18px;
    font-weight: 600;
  }
  &__section-title-row {
    margin-bottom: 10px;
  }
}
