.purchase-scenario {
  position: relative;
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    color: $g-title-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__section-wrapper {
    background-color: $g-additional-color-1;
    padding: 20px;
    @include bpm-xs {
      padding: 0;
      background-color: transparent;
    }
  }
  &__section {
    position: relative;
    margin-bottom: 20px;
  }
  &__section-title {
    font-size: 16px;
    font-weight: 600;
  }
  &__pass-tag {
    font-weight: normal;
    font-size: 15px;
    padding: 10px 10px;
    width: 100%;
    text-align: center;
  }
  &__status-indicator {
    padding: 10px;
    border-radius: 10px;
    background-color: $g-brand-color-1;
    cursor: pointer;
    color: $g-text-base;
    &--red {
      color: $g-text-base;
      background-color: $g-error;
    }
  }
  &__status-wrapper {
    display: flex;
    padding-left: 20px;
    align-items: center;
  }
  &__month-btn {
    width: 100px;
    height: 40px;
    margin-left: 15px;
  }
  &__add-max-btn {
    margin-left: 10px;
    height: 42px;
    width: 30%;
  }
  &__redraw-wrapper {
    display: flex;
    align-items: flex-end;
    .c-form-field {
      width: 70%;
      margin-bottom: 0px;
    }
  }
}
